<template>
  <div>
    <!-- <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="LBLDEPT"
            name="period"
            v-model="searchParam.deptCd"
          />
        </div>
      </template>
    </c-search-box> -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          :title="tabParam.title + ' 작업환경측정결과'"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="true"
          :columnSetting="false"
          :merge="grid.merge"
          :usePaging="false"
          :editable="!disabled"
          noDataLabel="작업환경측정결과를 추가하세요."
          selection="multiple"
          rowKey="workMeasurementResultId"
          @linkClick="linkClick"
        >
          <template slot="table-button">
            <q-btn-group outline>
              <c-btn v-if="updateMode && !disabled && editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
              <c-btn v-if="updateMode && !disabled && grid.data.length > 0 && editable" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="remove" />
              <!-- <c-btn label="LBLSEARCH" icon="search" @btnClicked="getListDate" /> datepicker로 조회할 수 있게 되어있는 부분 * 현재 datepicker 삭제해놓은 상태-->
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="workMeasurementResultId"
                ibmTaskTypeCd="ITT0000175"
                ibmTaskUnderTypeCd="ITTU000200"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='heaCheckup'">
              <q-btn
                v-if="Boolean(data.heaCheckupPlanId)"
                class="tableinnerBtn"
                flat round 
                :align="col.align"
                color="blue-6"
                icon="search">
                <q-popup-proxy @before-show="(e) => getCheckupResult(props)">
                <!-- <q-popup-proxy @before-show="getCheckupResult"> -->
                  <q-banner>
                    <c-table
                      ref="checkuptable"
                      title="검진결과 목록"
                      :columns="gridCheckup.columns"
                      :gridHeight="gridCheckup.height"
                      :merge="gridCheckup.merge"
                      :data="gridCheckup.data"
                      :filtering="false"
                      :columnSetting="false"
                      :usePaging="false"
                      :isExcelDown="false"
                      :isFullScreen="false"
                      :expandAll="true"
                      @linkClick="checkupResultPopOpen"
                    >
                    </c-table>
                  </q-banner>
                </q-popup-proxy>
              </q-btn>
            </template>
            <template v-else-if="col.name==='measValue'">
              <span>
                {{props.row.measValue}}
              </span>
              <span v-if="props.row.measRecent != null && props.row.measRecent > props.row.measValue" class="text-red-7">
                <br/> {{ (props.row.measRecent) }} (증가)  
              </span>
              <span v-else-if="props.row.measRecent == null" class="text-black-7">
                <br/> -
              </span>
              <span v-else-if="props.row.measRecent != null && props.row.measRecent < props.row.measValue" class="text-blue-7">
                <br/> {{ (props.row.measRecent) }} (감소)
              </span>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-measure-result-detail',
  props: {
    data: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
        plantCd: '',
        heaCheckupPlanId: '',
        updateFlag: '',
        workMeasurementResultModelList: [],
      }),
    },
    tabParam: {
      type: Object,
      default: function() {
        return {
          title: '화학적인자',
          hazardClassFstCd: 'HCF0000005'
        }
      },
    },
    disabled: {
      type: Boolean,
      default: false
    },
    updateMode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processDeptMergeGroup' },
          { index: 1, colName: 'processDeptMergeGroup' },
          { index: 2, colName: 'processDeptMergeGroup' },
        ],
        columns: [],
        data: [],
        height: '520px'
      },
      gridCheckup: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 50px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '관련유해인자',
            align: 'left',
            style: 'width: 450px',
            sortable: false,
          },
        ],
        data: [],
        height: '400px',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        processCd: '',
        processName: '',
        deptCd: '',
        workMeasurementPlanId: '',
        hazardClassFstCd: null,
        startYmd: '',
        endYmd: '',
      },
      measTime: [],
      date: [],
      editable: false,
      listCheckupUrl: '',
    };
  },
  watch: {
    'data.workMeasurementPlanId'() {
      this.getDetail();
    },
    'data.updateFlag'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['hazardName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.workmeas.result.list.url;
      this.insertUrl = transactionConfig.sai.workmeas.plan.insert.url;
      this.updateUrl = transactionConfig.sai.workmeas.plan.update.url;
      this.deleteUrl = transactionConfig.sai.workmeas.plan.delete.url;
      this.completeUrl = transactionConfig.sai.workmeas.plan.complete.url;
      this.deleteResultUrl = transactionConfig.sai.workmeas.result.delete.url;
      this.listCheckupUrl = selectConfig.hea.checkup.result.list.url;
      this.setHeader();
      // this.getDetail();
      this.getList();
    },
    getList() {
      this.grid.data = this.$_.filter(this.data.workMeasurementResultModelList, { hazardClassFstCd: this.tabParam.hazardClassFstCd });
    },
    // 현재 datepicker를 테이블에 추가하는 것을 보류해서 조회하는 메소드를 주석했음
    // getListDate() {
    //   if (this.date) {
    //     this.searchParam.startYmd = this.date[0];
    //     this.searchParam.endYmd = this.date[1];
    //   } else {
    //     this.searchParam.startYmd = '';
    //     this.searchParam.endYmd = '';
    //   }
    //   this.$http.url = this.detailUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = this.searchParam;
    //   this.$http.request((_result) => {
    //     this.grid.data = _result.data;
    //   },);
    // },
    setHeader() {
      if (this.tabParam.hazardClassFstCd === 'HCF0000005' || this.tabParam.hazardClassFstCd === 'HCF0000010') { 
        this.grid.columns = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'center',
            style: 'width:350px',
            type: 'link',
            sortable: false,
          },
          {
            fix: true,
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'chemName',
            field: 'chemName',
            label: '화학물질명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            required: true,
            name: 'exposureFlagName',
            field: 'exposureFlagName',
            label: '측정농도 평가결과',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'heaCheckup',
            field: 'heaCheckup',
            label: '특수검진<br/>대상자',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태_시간',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태_주기',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '유해인자발생시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '시간가중평균치(전회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '시간가중평균치(금회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measMethod',
            field: 'measMethod',
            label: '측정_분석방법',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ]
      } else {
        this.grid.columns = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            fix: true,
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            fix: true,
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            fix: true,
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            style: 'width:350px',
            align: 'center',
            type: 'link',
            sortable: false,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            required: true,
            name: 'exposureFlagName',
            field: 'exposureFlagName',
            label: '측정농도 평가결과',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'heaCheckup',
            field: 'heaCheckup',
            label: '특수검진<br/>대상자',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태_시간',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태_주기',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '유해인자발생시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '시간가중평균치(전회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '시간가중평균치(금회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measMethod',
            field: 'measMethod',
            label: '측정_분석방법',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ]
      }
    },
    // getList() {
    //   this.$http.url = this.detailUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = this.searchParam;
    //   this.$http.request((_result) => {
    //     this.grid.data = _result.data;
    //     if (this.tabParam.hazardClassFstCd === 'HCF0000005') {
    //       this.data.itemList1 = this.grid.data;
    //     } else if (this.tabParam.hazardClassFstCd === 'HCF0000001') {
    //       this.data.itemList2 = this.grid.data;
    //     } else {
    //       this.data.itemList3 = this.grid.data;
    //     }
    //   },);
    // },
    getDetail() {
      if (this.data.workMeasurementPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          workMeasurementPlanId: this.data.workMeasurementPlanId,
          hazardClassFstCd: this.tabParam.hazardClassFstCd,
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          let _workMeasurementResultModelList = [];
          _workMeasurementResultModelList = this.$_.filter(this.data.workMeasurementResultModelList, { hazardClassFstCd: this.tabParam.hazardClassFstCd }) ;
          this.$_.forEach(_workMeasurementResultModelList, item => {
            this.data.workMeasurementResultModelList = this.$_.reject(this.data.workMeasurementResultModelList, item);
          })
          this.$_.forEach(this.grid.data, item => {
            this.data.workMeasurementResultModelList.push(item);
          })
          this.searchParam.workMeasurementPlanId = this.$_.clone(this.data.workMeasurementPlanId)
          this.searchParam.hazardClassFstCd = this.$_.clone(this.tabParam.hazardClassFstCd)
        },);
      }
    },
    linkClick(row) {
      this.add(row);
    },
    add(row) {
      this.popupOptions.title = this.tabParam.title + ' 작업환경측정결과 상세'; // 작업환경측정결과 상세
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/workMeasureResultDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.param = {
        disabled: this.disabled,
        workMeasurementPlanId: this.data.workMeasurementPlanId,
        workMeasurementResultId: row ? row.workMeasurementResultId : '',
        hazardClassFstCd: this.tabParam.hazardClassFstCd,
        plantCd: this.data.plantCd,
        title: this.tabParam.title
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getDetail();
    },
    getCheckupResult(props) {
      this.$http.url = this.$format(this.listCheckupUrl, this.data.heaCheckupPlanId);
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: props.row.deptCd,
        hazardCd: props.row.hazardCd,
      };
      this.$http.request((_result) => {
        this.gridCheckup.data = _result.data;
      },);
    },
    checkupResultPopOpen(result) {
      this.popupOptions.target = () => import(`${"@/pages/hea/checkUpResultDetail.vue"}`);
      this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        heaCheckupResultId: result.heaCheckupResultId,
        checkupTypeCd: result.checkupTypeCd,
        checkupDate: result.checkupDate,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCheckupResultPopup;
    },
    closeCheckupResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '측정결과를 삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let deldata = [];
            this.$_.forEach(selectData, item => {
              deldata.push(item);
            })
            this.$http.url = this.deleteResultUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
                this.data.workMeasurementResultModelList = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });

      }
    },
    openExcelUploader() {
      this.popupOptions.title = '작업환경측정결과 업로드'; // 즉시개선
      this.popupOptions.param = {
        hazardClassFstCd: this.tabParam.hazardClassFstCd,
        plantCd: this.data.plantCd,
      }
      this.popupOptions.target = () => import(`${'./workMeasureResultExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.workMeasurementPlanId = this.data.workMeasurementPlanId
          item.workMeasurementResultId = uid();  // 일련번호
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = '';  // 수정자 ID
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.sai.workmeas.results.insert.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.$set(this.data, 'updateFlag', uid())
    },
  }
};
</script>
